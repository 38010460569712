export const clientData = (navigator: any) => {
    const userAgent = navigator.userAgent;
    let long = window.screen.height;
    let narrow = window.screen.width;
    if (long < narrow){
        let temp = long;
        long = narrow;
        narrow = temp;
    }

    const rate = long / narrow;
    let limitFullscreen = window.screen.height === window.screen.availHeight ? 1.8 : 1.65; // 临界判断值
    return {
        // 浏览器信息
        browser: {
            trident: userAgent.indexOf('Trident') > -1, //IE内核
            presto: userAgent.indexOf('Presto') > -1, //opera内核
            webKit: userAgent.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: userAgent.indexOf('Gecko') > -1 && userAgent.indexOf('KHTML') === -1,//火狐内核
            mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //iOS终端
            android: userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1, //Android终端
            iPhone: userAgent.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
            iPad: userAgent.indexOf('iPad') > -1, //是否iPad
            webApp: userAgent.indexOf('Safari') === -1, //是否web应该程序，没有头部与底部
            weixin: userAgent.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
            qq: userAgent.match(/\sQQ/i) === " qq" //是否QQ
        },
        // 是否为全面屏
        screen: {
            fullscreen: rate > limitFullscreen,
            tablet: rate >= 1.3 && rate <= 1.5
        },
        language: navigator.language.toLowerCase()
    }
};