import {IonContent, IonGrid, IonPage, IonCol, IonRow, IonButton, IonIcon} from '@ionic/react';
import {cloudDownloadOutline, downloadOutline, globeOutline} from 'ionicons/icons';
import './index.css';
import React from "react";
import {clientData} from "../../utils";

const IDCSClientDownload: React.FC = () => {
    const client = clientData(navigator);
    const language = () => {
        if (client.language === "zh-cn") return "cn"
        else if (client.language === "zh-tw" || client.language === "zh-hk") return "tw"
        else return "en";
    };
    let backgroundImage = `url(${process.env.PUBLIC_URL}/assets/background/landing_page.png)`;
    if (client.screen.fullscreen) backgroundImage = `url(${process.env.PUBLIC_URL}/assets/background/landing_page.png)`;
    if (client.screen.tablet) backgroundImage = `url(${process.env.PUBLIC_URL}/assets/background/idcs_client_landing_page_tablet.png)`;
    const background = {
        backgroundImage,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'
    };

    if (client.browser.ios) {
        // 这里填写apple store的app地址
        window.location.href = 'https://apps.apple.com/us/app/idcs-client/id1494065390';
    }
    if (client.browser.weixin || client.browser.qq) {
        // 这里写应用宝的idcs client地址
        window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.van.idcs_android&fromcase=40002';
    }

    const call_options = {
        schema: {
            protocol: "market"
        }
    };
    // const call_lib = new CallApp();
    return (
        <IonPage>
            <IonContent
                scrollY={false}
                fullscreen={true}>
                <div style={background}>
                    <div className="downloadButtonContainer">
                        <IonGrid fixed={true}>
                            <IonRow>
                                <IonCol size={"3"}/>
                                <IonCol className={"downloadButtonCell"}>
                                    <span className={"downloadVersion"}>Android Version 8</span>
                                </IonCol>
                                <IonCol size={"3"}/>
                            </IonRow>
                            <IonRow><br/></IonRow>
                            <IonRow><br/></IonRow>
                            <IonRow>
                                <IonCol/>
                                <IonCol className={"downloadButtonCell"}>
                                    <IonButton
                                        shape="round"
                                        className={"downloadButton"}
                                        fill={"outline"}
                                        expand={"full"}
                                        href={"https://hmi-hand-qiniu.idcs4iot.net/idcs_client/mainland_china.apk"}
                                        // onClick={() => {
                                        //     window.location.href = "https://hmi-hand-qiniu.idcs4iot.net/idcs_client.apk"
                                        // }}
                                    >
                                        <IonIcon slot="start" icon={cloudDownloadOutline}/>
                                        {language() === 'cn' ? "大陆用户" : language() === 'tw' ? "大陸用戶" : "China Version"}
                                    </IonButton>
                                </IonCol>
                                <IonCol/>
                                <IonCol className={"downloadButtonCell"}>
                                    <IonButton
                                        shape="round"
                                        className={"downloadButton2"}
                                        fill={"solid"}
                                        expand={"full"}
                                        href={"https://hmi-hand-qiniu.idcs4iot.net/idcs_client/global_version.apk"}
                                        // onClick={() => {
                                        //     window.location.href = "https://hmi-hand-qiniu.idcs4iot.net/idcs_client.apk"
                                        // }}
                                    >
                                        <IonIcon slot="start" icon={globeOutline}/>
                                        {language() === 'cn' ? "境外用户" : language() === 'tw' ? "非大陸地區" : "Global Version"}
                                    </IonButton>
                                </IonCol>
                                <IonCol/>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};


export default IDCSClientDownload;
